import React, { useState, useEffect } from 'react'
import Loader from "react-loader-spinner";

const DelayedFallback = () => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300)
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <>
      {show && 
      <div className="loading full">
                <Loader type="Bars" color="#c5d3e3" height={30} width={30} />
                <h6
                  style={{
                    color: "#c5d3e3",
                    fontSize: "14px",
                    marginTop: "10px",
                    marginLeft: "10px",
                  }}
                >
                  loading...
                </h6>
              </div>
              }

    </>
  )
}
export default DelayedFallback