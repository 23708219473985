import React,  { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet";

setGlobal({
  currentWaveform: null,
});

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      {/* <!-- Chrome, Firefox OS and Opera --> */}
      <meta name="theme-color" content="#edf2f9" />
      {/* <!-- Windows Phone --> */}
      <meta name="msapplication-navbutton-color" content="#edf2f9" />
      {/* <!-- iOS Safari --> */}
      <meta name="apple-mobile-web-app-status-bar-style" content="#edf2f9" />
    </Helmet>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


