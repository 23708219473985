import React, { lazy, Suspense } from 'react';
import { AuthProvider } from "../context/AuthContext";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css'
import DelayedFallback from './utils/DelayedFallback';
import { ToastContainer } from 'react-toastify';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import 'react-toastify/dist/ReactToastify.css';

const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Signup = lazy(() => import('./pages/Signup'));
const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const PrivateRoute = lazy(() => import('./utils/PrivateRoute'));
const UpdateProfile = lazy(() => import('./UpdateProfile'));
const Users = lazy(() => import('./pages/Users'));
const Upload = lazy(() => import('./pages/Upload'));
const MusicPage = lazy(()=> import('./pages/MusicPage'));
const PlaylistPage = lazy(()=> import('./pages/PlaylistPage'));
const Playlists = lazy(()=> import('./pages/Playlists'));

function App() {
  return (
    <Suspense fallback={<DelayedFallback/>}>
    <Router>
      
      <AuthProvider>
      
        <Switch>
          <PrivateRoute exact path="/" component={ Dashboard }/>
          <PrivateRoute exact path="/upload" component={ Upload }/>
          <PrivateRoute path="/users" component={ Users }/>
          <PrivateRoute path="/update-profile" component={ UpdateProfile }/>
          <PrivateRoute path="/playlists" component={ Playlists }/>

          <Route path="/signup" component={ Signup }/>
          <Route path="/login" component={ Login }/>
          <Route path="/forgot-password" component={ ForgotPassword }/>
          <Route path="/music/:id" component={ MusicPage }/>
          <Route path="/playlist/:id" component={ PlaylistPage }/>
        </Switch>
        
    </AuthProvider>
   
   </Router>
   <ToastContainer />
   </Suspense>
  );
}

export default App;
