import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { MeiliSearch } from "meilisearch";


const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})

const mClient = new MeiliSearch({
    host: "https://meilisearch.loehn-digital.com/",
    apiKey: 'ZWM2MDk4NmYxNGQ0MDA1Y2E1Y2RkOGYw'
  });

export const auth = app.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const fb = firebase;
export const client = mClient;
export default app;